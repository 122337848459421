<template>
    <b-overlay :show="loading">
        <b-row class="justify-content-center">
            <b-col sm="12" md="10" lg="8">
                <b-card>
                    <table class="w-full table">
                        <tr>
                            <td>Aset</td>
                            <td>:</td>
                            <th>{{ asset ? asset.nama : '-' }}</th>
                        </tr>
                        <tr>
                            <td>Jumlah</td>
                            <td>:</td>
                            <th>{{ mutasi.jumlah }}</th>
                        </tr>
                        <tr>
                            <td>Satuan</td>
                            <td>:</td>
                            <th>{{ asset && asset.satuan ? asset.satuan.satuan : '-' }}</th>
                        </tr>
                        <tr>
                            <td>Lokasi Awal Mutasi</td>
                            <td>:</td>
                            <th>
                                <span v-if="mutasi.dari_gudang">{{ mutasi.dari_gudang.nama_gudang}}</span>
                                <i class="text-danger" v-else>Lokasi awal tidak ditemukan</i>
                            </th>
                        </tr>
                        <tr>
                            <td>Tujuan Mutasi</td>
                            <td>:</td>
                            <th>
                                <span v-if="mutasi.tujuan_gudang">{{ mutasi.tujuan_gudang.nama_gudang}}</span>
                                <i class="text-danger" v-else>Tujuan tidak ditemukan</i>
                            </th>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>:</td>
                            <th>
                                <b-badge variant="light-success" v-if="mutasi && mutasi.terima == 1">Diterima</b-badge>
                                <b-badge variant="light-danger" v-else>Belum Diterima</b-badge>
                            </th>
                        </tr>
                    </table>
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>
<script>
import {BOverlay, BRow, BCol, BCard, BBadge} from 'bootstrap-vue'
export default {
    components: {
        BOverlay, BRow, BCol, BCard, BBadge
    },
    data: () => ({
        loading: false,
        gudangs: [],
        asset: null,
        penyimpanan_dari: null
    }),
    computed: {
        mutasi() {
            return this.$store.getters['asset/searchRequestMutation'](this.$route.params.id)
        }
    },
    methods: {
        getGudangName(gudang_id) {
            const find = this.gudangs.find(item => item.id == gudang_id)
            return find ? find.nama_gudang : '-'
        },
        async getAsset() {
            this.asset = await this.$store.dispatch('asset/getBarangById', this.mutasi.asset_id)
        },
        async getPenyimpananDari() {
            this.penyimpanan_dari = await this.$store.dispatch('asset/getStockById', this.mutasi.penyimpanan_dari)
        },
        async getGudang() {
            this.gudangs = await this.$store.dispatch('gudang/getData', {jenis: 1})
        }
    },
    async created() {
        if(!this.mutasi) {
            this.$router.push(`/asset-mutasi-request`)
        }
        await this.getGudang()
    },
    mounted() {
        this.getAsset()
        this.getPenyimpananDari()
    }
}
</script>